import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../../../assets/arrow-white.png";
import Bell from "../../../../assets/notification-page.png";
import notificationIcon from "../../../../assets/notification-circle.svg";
import buttomLine from "../../../../assets/line.svg";
import Settings from "../../Settings";

const Notifications = () => {
  const [notificationList,setNotification] = useState(true)

  useEffect(() => {
    setTimeout(() => setNotification(false), 1000);
  }, []);
  return (
    <>
      {/* <Settings /> */}
      <div class="section">
        <div class="height-100">
          <div class="top">
            <div class="container-fluid px-md-100 mt-4">
              <div class="row">
                <div class="col-md-12">
                  <Link className="back-buttons" to={"/dashboard"}>
                    <img src={backArrow} alt="arrow" class="img-fluid" />
                  </Link>
                  <h4 class="design-color fw-bold mt-4 mb-5 text-center">
                    Notifications
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="middle">
            {
              notificationList ? <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 mx-auto text-center">
                <img src={Bell} alt="line" class="img-fluid w-50 mx-auto  d-block" />
                <p>No notifications at the moment.</p>
                </div>
              </div>
            </div>: <div class="container-fluid px-md-100 newNotify">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="text-black fw-semibold text-uppercase">Today</h6>

                  <div class="d-flex justify-content-between py-4 align-items-center border-bottom">
                    <div class="notify-image">
                      <img
                        src={notificationIcon}
                        alt="notification"
                        class="img-fluid"
                      />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>

                    <div class="">
                      <p class="design-color small mb-0">2 min</p>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between py-4 align-items-center ">
                    <div class="notify-image notification-image">
                      <img
                        src={notificationIcon}
                        alt="notification"
                        class="img-fluid"
                      />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem ipsum dolor, sit amet
                      consectetur adipisicing elit. Ipsa, ab consectetur,
                      laboriosam delectus totam sequi, nesciunt eligendi sit
                      corrupti expedita unde alias facilis.
                    </div>

                    <div class="">
                      <p class="design-color small mb-0">2 min</p>
                    </div>
                  </div>

                  <h6 class="text-black fw-semibold text-uppercase yesterday">
                    yesterday
                  </h6>

                  <div class="d-flex justify-content-between py-4 align-items-center border-bottom">
                    <div class="notify-image">
                      <img
                        src={notificationIcon}
                        alt="notification"
                        class="img-fluid"
                      />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem ipsum dolor sit es unde
                      exercitate iure illum, explicabo odit! Tenetur
                      necessitatibus provident sapiente?
                    </div>

                    <div class="">
                      <p class="design-color small mb-0">2 min</p>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between py-4 align-items-center ">
                    <div class="notify-image">
                      <img
                        src={notificationIcon}
                        alt="notification"
                        class="img-fluid"
                      />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem ipsum dolor sit aperiam alias
                      expedita ipsum amet repellat accusamus, quasi ad assumenda
                      facilis vel error adipisci explicabo.
                    </div>

                    <div class="">
                      <p class="design-color small mb-0">2 min</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>


          <div class="bottom">
            <div class="container-fluid px-md-100">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center">
                    <img src={buttomLine} alt="line" class="line img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
