import axios from "axios";
import { GetUrl } from "../config/GetUrl";
export const isLoggedIn = () => {
  let data = localStorage.getItem("token");
  if (data != null) return true;
  else return false;
};

export const doLogin = (token, fullName, email) => {
  localStorage.setItem("token", token);
  localStorage.setItem("fullName", fullName);
  localStorage.setItem("email", email);
  //next()
};

export const doLogout = (next) => {
  localStorage.removeItem("fullName");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
  //next()
};

export const isAccountActive = async () => {
  try {
    const response = await axios.get(GetUrl.API_URL + "/retailer/profile", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data.data.is_deactivated; // Assuming the response returns { isActive: true/false }
  } catch (error) {
    console.error("Error checking account status", error);
    return false;
  }
};
