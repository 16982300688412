// src/notifications.js
import { toast } from 'react-toastify';


// Ensure you install `react-toastify` using: npm install react-toastify

export const toastNotification = ({ title, description, status }) => {
  console.log("===useDispatch===")
  toast.info(`${title} - ${description}`);
};

export const sendNativeNotification = ({ title, body }) => {
  if (Notification.permission === 'granted') {
    new Notification(title, { body });
  }
};
