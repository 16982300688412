import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    GetAllCategory: builder.query({
      query: ({categoryIds}) => ({
        url: `/retailer/get-all-category?categories=${categoryIds}&subcategories=`,
        method: "GET",
        headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
        
      }),
  }),
});

export const {
  useGetAllCategoryQuery
} = categoryApi;
