import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationCount: 0,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    incrementNotificationCount: (state) => {
      state.notificationCount += 1;
    },
    resetNotificationCount: (state) => {
      state.notificationCount = 0;
    },
  },
});

export const { incrementNotificationCount, resetNotificationCount } = notificationSlice.actions;
export default notificationSlice.reducer;
