import React from 'react'
import pageNotrFoundIcon from "../assets/pageNotFound.png"

const PageNotFound = () => {
  return (
    <div className="layout has-sidebar fixed-sidebar fixed-header">
         <div id="overlay" className="overlay"></div>
         <div className="col-md-12 middle mt-4 px-md-5">
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <img src={pageNotrFoundIcon} alt="logo" className="img-fluid" />
                </div>
                <h4 className="fw-semibold text-color text-center mt-3">Page Not Found</h4>
            </div>
         
         
    </div>
  )
}

export default PageNotFound