
export const FormValidation = ({ inputField, setErrField }) => {
    const validateForm = () => {
        let errors = {};
        if (!inputField?.email?.trim()) {
            errors.emailErr = "Please enter email";
        } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
            errors.emailErr = "Please enter a valid email";
        }
        if (!inputField?.subject?.trim()) {
            errors.subjectErr = "Please enter subject";
        }
        if (!inputField?.description?.trim()) {
            errors.descriptionErr = "Please enter description";
        }
        setErrField(errors);
        return Object.keys(errors)?.length === 0;
    };

    return validateForm();
};

export const FormValidationOnChange = ({ inputValue, setErrField, inputName }) => {
    const validateForm = () => {
        switch (inputName) {
            case "email":
                setErrField((prevState) => ({
                    ...prevState,
                    emailErr: inputValue?.length > 5 ? "" : "Email must be at least 6 characters long",
                }));
                break;
            case "subject":
                setErrField((prevState) => ({
                    ...prevState,
                    subjectErr: inputValue?.length > 3 ? "" : "Subject must be at least 4 characters long",
                }));
                break;
            case "description":
                setErrField((prevState) => ({
                    ...prevState,
                    descriptionErr: inputValue?.length > 5 ? "" : "Description must be at least 6 characters long",
                }));
                break;
            default:
                break;
        }
    };

    return validateForm();
};

