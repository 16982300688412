import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const verificationApi = createApi({
  reducerPath: "verificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    VerificationPost: builder.mutation({
      query: (data) => ({
        url: "/verification/verify-otp",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    ResendOtp: builder.mutation({
      query: (data) => ({
        url: "/verification/resend-otp",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),

  }),
});

export const {
 useVerificationPostMutation,
 useResendOtpMutation
} = verificationApi;
