//import "./LoadingAction.css";
import { RotatingLines } from 'react-loader-spinner'
const LoadingImage = () => {

    return (
        <div className="loading-image">
            <RotatingLines
  visible={true}
  height="40"
  width="40"
  color="red"
  strokeColor='#C73618'
  strokeWidth="5"
  animationDuration="0.75"
  ariaLabel="rotating-lines-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
        </div>
    )
}

export default LoadingImage;