import React,{useState} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import helpIcon from "../../../assets/help.svg"
import logoutICon from "../../../assets/logout.svg"
import rightArrow from "../../../assets/arrow-right.svg"
import LogoutModal from './LogoutModal'

const Support = () => {
    const navigate = useNavigate();
    const [show,setShow] = useState(false)

const logoutHandler = () =>{
   
    setShow(true)
}
const handleClose = () =>{
    setShow(false)
}

    return (
        <>
            {/* <p className="my-3 text-uppercase text-decoration-underline">Support</p> */}
           <LogoutModal show={show} handleClose={handleClose}/>
            <Link to={'/settings/help-and-support'} className="border-0 p-0  list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={helpIcon} alt="help" className="img-fluid me-3" /> Help Support
                    </div>
                </div>
                {/* <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span> */}
            </Link>

            {/* <a onClick={logoutHandler} className="border-0 p-0 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images" >
                        <img src={logoutICon} alt="logout" className="img-fluid me-3" /> Log Out
                    </div>
                </div>
            </a> */}
        </>
    )
}

export default Support