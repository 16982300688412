import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const supportApi = createApi({
  reducerPath: "supportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    SupportPost: builder.mutation({
      query: (data) => ({
        url: "/retailer/support",
        method: "POST",
        body: data,
        headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        
      }),
    }),

  }),
});

export const {
 useSupportPostMutation
} = supportApi;
