import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    GetOrderStatus: builder.query({
      query: ({status}) => ({
       url:`/retailer/get-Order-status?status=${status}`,
        method: "GET",
        headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
        
      }),
      GetOrderDetail: builder.query({
        query: ({id}) => ({
          url: `/retailer/get-Order-status-detail/${id}`,
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }),
      }),
  }),
});

export const {
  useGetOrderStatusQuery,
  useGetOrderDetailQuery
} = orderApi;