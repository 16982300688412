import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    GetFAQ: builder.query({
        query: () => ({
         url:`/retailer/get-faqs`,
          method: "GET",
          headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        }),
  }),
});

export const {
 useGetFAQQuery
} = settingApi;
