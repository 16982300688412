
export const FormValidation = ({ inputField, setErrField,subCategoryList }) => {
    const validateForm = () => {
        let errors = {};
        console.log('inputField---->',inputField);
        if(!inputField?.productName?.trim()){
            errors.productNameErr = "Please enter product name";
        }
        if(!inputField?.description?.trim()){
            errors.descriptionErr = "Please enter description";
        }
        if(!inputField?.category?.trim()){
            errors.categoryErr = "Please enter category name";
        }
        // if(!inputField?.selectedValueProductType?.trim()){
        //     errors.selectedValueProductTypeErr = "Please select product type";
        // }
        if(!inputField?.itemWeight?.trim()){
            errors.itemWeightErr = "Please enter item weight";
        }
        if(!inputField?.itemPrice){
            errors.itemPriceErr = "Please enter item price";
        }
        if(!inputField?.quantity?.trim()){
            errors.quantityErr = "Please enter quantity";
        }
        // if(subCategoryList.length>0){
        //     if(!inputField?.subCategory?.trim()){
        //         errors.subCategoryErr = "Please enter sub category";
        //     }
        // }

        setErrField(errors);
        return Object.keys(errors)?.length === 0;
    };

    return validateForm();
};



