import React, { useState, useEffect } from "react";
import "./App.css";
import "react-phone-input-2/lib/style.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Splash from "./components/Splash";
import Login from "./components/login/Login";
import ChooseAccount from "./components/chooseAccount/ChooseAccount";
import Register from "./components/register/Register";
import Verification from "./components/verification/Verification";
import CreateProfile from "./components/createProfile/CreateProfile";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import ForgotVerification from "./components/forgotPassword/ForgotVerification";
import ResetPassword from "./components/forgotPassword/ResetPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import Products from "./pages/products/Products";
import MyMenu from "./pages/myMenu/MyMenu";
import Settings from "./pages/settings/Settings";
import AddProduct from "./pages/products/addProduct/AddProduct";
import OrderDetails from "./pages/myMenu/orderDetails/OrderDetails";
import ViewUserProfile from "./pages/settings/accountInformation/userProfile/ViewUserProfile";
import EditUserProfile from "./pages/settings/accountInformation/userProfile/EditUserProfile";
import ChangePassword from "./pages/settings/accountInformation/changePassword/ChangePassword";
import Notifications from "./pages/settings/accountInformation/notifications/Notifications";
import Faq from "./pages/settings/legal/faq/Faq";
import AboutUs from "./pages/settings/legal/aboutUs/AboutUs";
import PrivacyPolicy from "./pages/settings/legal/privacyPolicy/PrivacyPolicy";
import TermCondition from "./pages/settings/legal/termCondition/TermCondition";
import HelpAndSupport from "./pages/settings/support/helpAndSupport/HelpAndSupport";
import Private from "./components/Private";
import PageNotFound from "./pageNotFound/PageNotFound";
import EditProduct from "./pages/products/editProduct/EditProduct";
import { AccountProvider } from "./components/AccountContext";
import { setupNotifications } from './firebase';



function App() {
  const [splash, setSplash] = useState(true);
  const [dataPass, setDataPass] = useState("");

  useEffect(() => {
    setTimeout(() => setSplash(false), 500);
  }, []);

  const passData = (newData) => {
    setDataPass(newData);
  };

  useEffect(() => {
    setupNotifications();
  }, []);



  return splash ? (
    <Splash />
  ) : (
    <AccountProvider>
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/choose-account" element={<ChooseAccount />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/forget-verification" element={<ForgotVerification />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* ========Private Route============= */}
        <Route path="/" element={<Private />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="products" element={<Products />} />
          <Route path="create-product" element={<AddProduct />} />
          <Route path="edit-product" element={<EditProduct />} />
          <Route path="my-menu" element={<MyMenu />} />
          <Route path="order-details/:id" element={<OrderDetails />} />
          <Route path="settings" element={<Settings />} />
          <Route
            path="settings"
            element={<ViewUserProfile dataPass={dataPass} />}
          />
          {/* <Route
            path="settings/view-user-profile"
            element={<ViewUserProfile dataPass={dataPass} />}
          /> */}
          <Route
            path="edit-user-profile"
            element={<EditUserProfile passData={passData} />}
          />
          <Route path="settings/change-password" element={<ChangePassword />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="faq" element={<Faq />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="term-and-condition" element={<TermCondition />} />
          <Route path="settings/help-and-support" element={<HelpAndSupport />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </AccountProvider>
  );
}

export default App;
