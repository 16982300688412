import React from 'react'
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Logout from "../../../assets/product-delete.png";

const DeleteProduct = (props) => {
    const { show, handleClose,handleDelete } = props;
    const cancelHandler = () =>{
        handleClose();
    }

    const deleteProductHandler = ()=>{
      handleDelete()
      handleClose()
      
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header
      closeButton={true}
      className="border-0 mt-4 pt-0 pb-2"
    ></Modal.Header>
    <Modal.Body className="text-center pt-0">
      <div className="modal-body text-center pb-0 px-5 pt-0">
      <Image src={Logout} alt="image" className="img-fluid logout-width" />
        <h4 class="design-color fw-bold my-0  mx-auto">
          Are you sure you want to delete this product?
        </h4>
      </div>
      <div className="modal-footer d-block text-center border-0">

      <div>
          <button
            type="button"
            className="btn text-black fw-bold ok-delete py-3 w-100"
            onClick={deleteProductHandler}
          >
            Ok
          </button>
        </div>

        <div>
          <button
            type="button"
            className="btn text-73 text-uppercase"
            aria-label="Close"
            onClick={cancelHandler}
          >
            Cancel
          </button>
        </div>
        
       
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default DeleteProduct