import React from 'react';
import { Link } from 'react-router-dom'
import backArrow from "../../../../assets/arrow-white.png"
import buttomLine from "../../../../assets/line.svg"
import Sidebar from "../../../../components/Sidebar";
import dlogo from "../../../../assets/dlogo.png";
import notifications from "../../../../assets/notification-circle.svg";

const PrivacyPolicy = () => {
    return (
        <>

<div className="container-fluid bg-five">
        <div className="row px-0 pb-5 m-0">
          <div className="col-md-2 col-lg-1 new ">
            <Sidebar />
          </div>


          <div className="col-md-10 col-lg-11">
          <div className="section">
            <div className="">
                <div className="top">
                    <div className="container-fluid  mt-40 p-0 mx-0">
                        <div className="row  align-items-center p-0 m-0 mb-5">
                        <div className="col-3 p-0 m-0">
                        <Link className="back-buttons back-button-mobile" to={"/settings"}>
                          <img src={backArrow} alt="arrow" class="img-fluid" />
                        </Link>
                      </div>
                   
                    <div className="col-6 p-0 m-0">
                    <img
                            src={dlogo}
                            alt="notification"
                            className="img-fluid mx-auto d-block"
                            style={{width: 150}}
                          />
                      </div>

                           

                            <div className="col-3 p-0 m-0">
                        <Link
                          to={"/notifications"}
                          className="notificaition-common border-0 p-0 list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div className="ms-auto">
                            <div className="h5 mb-0 d-flex align-items-end setting-images">
                              <img
                                src={notifications}
                                alt="notifications"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </Link>
                      </div>
                        </div>
                    </div>
                </div>

                <div className="middle textGrey middle-content">
                    <div className="container-fluid px-0 p-0 mt-4">
                        <div className="row">
                        <div className="col-md-6">
                                {/* <Link  className="back-buttons" to={'/settings'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link> */}
                                <h5 className="text-black fw-bold mb-4 borderLEFT">Privacy Policy</h5>
                            </div>

                            <div className="col-md-12">
                                <p className="fs-5 textGrey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                    make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                                    versions of Lorem Ipsum.</p>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0 mx-0 mt-2">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="fs-5 textGrey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                    make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                                    versions of Lorem Ipsum.</p>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0 mx-0 mt-2">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className='common-ul'>
                                    <li className="fs-5 textGrey"> Lorem ipsum dolor sit amet, consectetur</li>
                                    <li className="fs-5 textGrey">Adipiscing elit.</li>
                                    <li className="fs-5 textGrey">Donec a elit quis dolor euismod dignissim.</li>
                                    <li className="fs-5 textGrey">Nullam non odio condimentum, rhoncus nunc</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid p-0 mx-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          </div>

          </div>
          </div>
      

        </>
    )

}
export default PrivacyPolicy