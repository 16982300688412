import React from 'react'
import SplashIcon from "../assets/logo.png"

const Splash = () => {
  return (
    <div className="bg-image">
        <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <img src={SplashIcon} alt="logo" className="img-fluid splash-logo" />
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Splash