import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 product_id:"",
 updated_product:"",
 notificationCount:0
};

export const productSlice = createSlice({
  name: "createProfile",
  initialState,
  reducers: {
    setProductID(state, action) {
      state.product_id = action.payload;
    },
    setUpdatedProduct(state, action) {
      state.updated_product = action.payload;
    },
    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProductID,
  setUpdatedProduct,
  setNotificationCount
} = productSlice.actions;

export default productSlice.reducer;