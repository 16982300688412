import React, { useState, useEffect } from "react";
import Navbar from "../ButtomNav/Navbar";
import { Link } from "react-router-dom";
import notificationIcon from "../../assets/notification-circle.svg";
import dlogo from "../../assets/dlogo.png";
import Products from "../../assets/retail.png";
import Orders from "../../assets/orders.png";
import Earning from "../../assets/earning.png";
import LineChart from "./LineChart";
import WeeklyActivity from "./Chart/WeeklyActivity";
import PerHour from "./Chart/perHour/PerHour";
import PerDay from "./Chart/perDay/PerDay";
import PerWeek from "./Chart/perWeek/PerWeek";
import PerMonth from "./Chart/perMonth/PerMonth";
import Sidebar from "../../components/Sidebar";

import { useSelector } from "react-redux";

const Dashboard = () => {
  const [usrname, setUsername] = useState(localStorage.getItem("fullName"));
  const [graph, setGraph] = useState("perHour");
  const [notificationCount, setNotificationCount] = useState(2);
  const notificationCounts = useSelector(
    (state) => state.notifications.notificationCount
  );


  const orders = [
    {
      id: "#456432432",
      orderDateTime: "11 March 2024, 03:00 AM",
      deliveryDateTime: "12 March 2024, 7:00 PM - 8:00 PM",
    },
    {
      id: "#456432433",
      orderDateTime: "12 March 2024, 03:00 AM",
      deliveryDateTime: "13 March 2024, 7:00 PM - 8:00 PM",
    },
    {
      id: "#456432434",
      orderDateTime: "13 March 2024, 03:00 AM",
      deliveryDateTime: "14 March 2024, 7:00 PM - 8:00 PM",
    },
    // Add more objects for each order as needed
  ];

  useEffect(() => {});

  const submitHandlerAllGraph = (e, graph) => {
    e.preventDefault();
    setGraph(graph);
  };

  return (
    <>
      <div className="container-fluid PM_0">
        <div className="row px-0 pb-md-5 m-0">
          <div className="col-lg-1 col-md-2 new ">
            <Sidebar />
          </div>
          <div className="col-lg-11 col-md-10  PM_0">
            <div className="section">
              <div className="height-100">
                <div className="top">
                  <div className="container-fluid mt-40 p-0 mx-0">
                    <div className="row align-items-center p-0 m-0 mb-4">

                    <div className="col-3 p-0 m-0">
                        {/* <h5 className="text-black fw-bold mb-0 design-color">Home</h5> */}
                      </div>
                   
                    <div className="col-6 p-0 m-0">
                    <img
                            src={dlogo}
                            alt="notification"
                            className="img-fluid mx-auto d-block"
                            style={{width: 150}}
                          />
                      </div>
                     
                      <div className="col-3 p-0 m-0">
                        <Link to={"/notifications"} className="notificaition-common float-end position-relative">
                          <img
                            src={notificationIcon}
                            alt="notification"
                            className="img-fluid noti-width"
                          />
                          {notificationCount > 0 && (
                            <span className="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-circle">
                              {notificationCount}
                            </span>
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="row align-items-center p-0 mx-0 mt-md-4 pt-md-4 pt-1 pb-3 pb-md-0">
                      <div className="col-12 p-0 m-0">
                        <h4 className="text-black fw-bold mb-0">
                          Welcome,
                          <span className="design-color h4 ms-2 fw-bold">
                            {usrname}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="middle">
                  <div className="container-fluid mt-md-4 mx-0 p-0">
                    <div className="row">


                      <div className="col-xl-3 col-lg-4 col-md-4">
                        <div className="card  products border-0 me-xl-5 me-md-1 total-order">
                          <div className=" row card-body p-4 total-order-card">
                            
                          <div className="col-4">
                            <img
                            src={Products}
                            alt="notification"
                            className=""
                          />
                            </div>

                            <div className="col-8">
                            <p className=" textGrey mb-1">Total Products</p>
                              <h2 className="fw-bolder design-color mb-0">30</h2>
                              
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4">
                      <div className="card orders border-0  me-xl-5 me-md-1 total-order">
                          <div className="row card-body p-4 total-order-card">
                          <div className="col-4">
                            <img
                            src={Orders}
                            alt="notification"
                            className=""
                          />
                            </div>
                            <div className="col-8">
                            <p className="mb-1 textGrey">Total Orders</p>
                              <h2 className="fw-bolder design-color mb-0">120</h2>
                              
                            </div>

                            
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-4">
                      <div className="card earnings border-0  me-xl-5 me-md-1 total-order">
                          <div className="row card-body p-4 total-order-card">
                          <div className="col-4">
                            <img
                            src={Earning}
                            alt="notification"
                            className=""
                          />
                            </div>
                            <div className="col-8">
                            <p className="mb-1 textGrey">Total Earnings</p>
                              <h2 className="fw-bolder design-color mb-0">$3120</h2>
                            
                            </div>

                           
                          </div>
                        </div>
                      </div>
                      





                      <div className="col-md-12 mt-5">
                        <p className="mb-2 text-black borderLEFT text-uppercase text-decoration-underline">
                          Confirmed Orders
                        </p>
                      </div>
                      <div className="col-md-12 mb-5">
                        <div className="mb-3 chart-main">
                         <div className="card p-4 border-0">
                          <div className="card-body">
                          <LineChart />
                          </div>
                         </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-5">
                        <p className="mb-2 text-uppercase text-black text-decoration-underline borderLEFT">
                          Revenue
                        </p>
                        <div className="mb-3 chart-main-weekly">
                        <div className="card p-4 border-0">
                          <div className="card-body">
                          <WeeklyActivity />
                          </div>
                         </div>

                         
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <p className="mb-2 text-uppercase  text-black text-decoration-underline borderLEFT">
                          Order size
                        </p>
                      <div className="card p-4 border-0">
                        <div className="card-body pt-0">
                        <ul
                          className="nav nav-pills average-order-size mb-3 neww"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              onClick={(e) =>
                                submitHandlerAllGraph(e, "perHour")
                              }
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Hour
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              onClick={(e) =>
                                submitHandlerAllGraph(e, "perDay")
                              }
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Day
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              onClick={(e) =>
                                submitHandlerAllGraph(e, "perWeek")
                              }
                              id="pills-contact-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-contact"
                              type="button"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              Week
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              onClick={(e) =>
                                submitHandlerAllGraph(e, "perMonth")
                              }
                              id="pills-disabled-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-disabled"
                              type="button"
                              role="tab"
                              aria-controls="pills-disabled"
                              aria-selected="false"
                            >
                              Month
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabindex="0"
                          >
                            ...
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                          >
                            ...
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-contact"
                            role="tabpanel"
                            aria-labelledby="pills-contact-tab"
                            tabindex="0"
                          >
                            ...
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-disabled"
                            role="tabpanel"
                            aria-labelledby="pills-disabled-tab"
                            tabindex="0"
                          >
                            ...
                          </div>
                        </div>
                        <div className="mb-3 chart-main-static mt-3">
                          {graph === "perHour" && <PerHour />}
                          {graph === "perDay" && <PerDay />}
                          {graph === "perWeek" && <PerWeek />}
                          {graph === "perMonth" && <PerMonth />}
                        </div>
                        </div>

                      </div>
                      </div>

                      {/* <div className="col-md-12">
                <p className="mb-2 text-uppercase text-decoration-underline">
                  Upcoming Orders
                </p>
                {orders.map((order, index) => (
                  <a href="" className="text-decoration-none" key={index}>
                    <div className="card tab-card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <p className="mb-1">Order ID</p>
                            <h6 className="design-color">{order.id}</h6>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <button className="btn btn-delivery">
                                Delivery
                              </button>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <p className="mb-1">Order Date, Time</p>
                            <h6 className="design-color">
                              {order.orderDateTime}
                            </h6>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="float-end">
                              <p className="mb-1">Delivery Date, Time</p>
                              <h6 className="design-color">
                                {order.deliveryDateTime}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div> */}
                    </div>
                  </div>
                </div>

                <Navbar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
