import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const userProfileApi = createApi({
  reducerPath: "userProfileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    RetrieveProfile: builder.query({
      query: () => ({
        url: "/retailer/profile",
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),
    EditUserProfile: builder.mutation({
      query: (data) => ({
        url: "/retailer/edit-user-profile",
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),
    UserAccountDeleted: builder.mutation({
      query: () => ({
        url: "/retailer/user-account-delete",
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useRetrieveProfileQuery,
  useEditUserProfileMutation,
  useUserAccountDeletedMutation,
} = userProfileApi;
