import React from "react";

export const FormValidation = ({ inputField, setErrField }) => {
    const validateForm = () => {
        let errors = {};
        if (!inputField?.email?.trim()) {
            errors.emailErr = "Please enter email";
        } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
            errors.emailErr = "Please enter a valid email";
        }
        if (!inputField?.password?.trim()) {
            errors.passwordErr = "Please enter password";
        }
        setErrField(errors);
        return Object.keys(errors)?.length === 0;
    };

    return validateForm();
};

export const FormValidationOnChange = ({ inputValue, setErrField, inputName }) => {
    const validateForm = () => {

        if (inputName === "email" && inputValue?.length > 3) {
            setErrField((prevState) => ({
                ...prevState,
                emailErr: "",
            }));
        }
        if (inputName === "password" && inputValue?.length > 5) {
            setErrField((prevState) => ({
                ...prevState,
                passwordErr: "",
            }));
        }
    };

    return validateForm();
};

