import React from 'react'
import { Link,useNavigate } from 'react-router-dom'
import HomeIcon from "../../assets/home.svg"
import menuActive from "../../assets/menu-active.svg"
import productIcon from "../../assets/product.svg"
import settingGrey from "../../assets/setting-grey.svg"

const Navbar = () => {
    return (
        <div className="bottom design-bgcolor fixed-bottom">
            <div className="container-fluid px-md-100">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <ul className="nav d-flex justify-content-between">
                            <li>
                                <Link className="d-flex flex-column pb-2 pt-3" to={'/dashboard'}>
                                    <img src={HomeIcon} alt="home" className="img-fluid img-size" />
                                    <span className="text-d2 fs-5 mt-md-2 mt-1">Home</span>
                                </Link>
                            </li>

                            <li>
                                <Link className="d-flex flex-column pb-2 pt-3 active" to={'/my-menu'}>
                                    <img src={menuActive} alt="menu" className="img-fluid img-size" />
                                    <span className="text-d2 fs-5 mt-md-2 mt-1">My Orders</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="d-flex flex-column pb-2 pt-3" to={'/products'}>
                                    <img src={productIcon} alt="product" className="img-fluid img-size" />
                                    <span className="text-d2 fs-5 mt-md-2 mt-1">My Products</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="d-flex flex-column pb-2 pt-3" to={'/settings'}>
                                    <img src={settingGrey} alt="setting" className="img-fluid img-size" />
                                    <span className="text-d2 fs-5 mt-md-2 mt-1">Settings</span>
                                </Link>
                            </li>
                        </ul>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar