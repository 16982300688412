import React, { createContext, useState, useEffect } from "react";
import { isAccountActive } from "../auth";

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    const fetchAccountStatus = async () => {
      const status = await isAccountActive();
      setIsActive(status);
    };

    fetchAccountStatus();
  }, []);

  return (
    <AccountContext.Provider value={{ isActive }}>
      {children}
    </AccountContext.Provider>
  );
};
