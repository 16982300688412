import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backArrow from "../../assets/arrow-white.png"
import buttomLine from "../../assets/line.svg"
import Forgot from "../../assets/forgot.png"
import { useForgotPasswordPostMutation } from "../../Services/ForgotPasswordApi"
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const [forgotPass] = useForgotPasswordPostMutation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('')


    const resetPasswordHandler = (e) => {
        e.preventDefault();
        let userData = {
            email: email
        }
        forgotPass(userData)
            .unwrap()
            .then((res) => {
                localStorage.setItem("email", res?.data?.email);
                toast.success(res?.message);
                if (res?.success) {
                    navigate("/forget-verification");
                }
            })
            .catch((err) => {
                toast.error(err?.data?.message);
            });
    }

    return (
        <div className="section bg-colorss real">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <Link className="back-buttons" to={'/login'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                {/* <h4 className="design-color fw-bold mt-4">Forgot Password?</h4>
                                <p className="mt-3 text-muted fs-5 mb-0">We’ll send you a verification code</p> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 h-100">
                        <div className="row h-100 align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-7">


                                <div className="card border-0 rounded">
                                    <div className="card-body p-md-5">

                                        <h3 className="design-color fw-bolder mb-0 text-center">Forgot Password?</h3>
                                        <p className='text-muted text-center'>We’ll send you a verification code</p>

                                        <img src={Forgot} alt="forgot" className="img-fluid forgot-size my-4 mx-auto d-block" />

                                        <form action="" className="form-login">
                                            <div className="row justify-content-center mt-3">
                                                <div className="col-md-12">
                                                    <div className="">
                                                        <label className='form-label'>Email</label>
                                                        <input type="email"
                                                            className="form-control py-3"
                                                            placeholder="Enter Email Address"
                                                            aria-label="email" aria-describedby="email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-md-10 mx-auto'>
                                                <div className='text-center'>
                                                    <button type="submit"
                                                        onClick={resetPasswordHandler}
                                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3">Reset Password</button>
                                                </div>
                                            </div>
                                        </form>

                                        <h6 className="mt-4 fw-normal text-center">
                                            <span className="text-black me-2">Don’t have an account?</span>
                                            <Link to={'/register'} className="design-color text-decoration-none">Sign up</Link>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword