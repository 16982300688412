import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Delete from "../../../../assets/delete.png"
import { useUserAccountDeletedMutation } from "../../../../Services/UserProfileApi";
import {doLogout} from "../../../../auth/index"

const DeleteUser = (props) => {
  const [deleteAccount] = useUserAccountDeletedMutation();
  const { show, handleClose } = props;

  const cancelHandler = () => {
    handleClose();
  };

  const deleteAccountHandler = (e) => {
    e.preventDefault();
    deleteAccount()
      .unwrap()
      .then((res) => {
        doLogout();
      })
      .catch((err) => {
        console.log();
        // toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header
        closeButton={true}
        className="border-0 mt-4 pt-0 pb-2"
      ></Modal.Header>
      <Modal.Body className="text-center pt-0">
        <div className="modal-body text-center pb-0 px-5 pt-0">
        <Image src={Delete} alt="image" className="img-fluid delete-width" />
          <h4 class="design-color fw-bold my-0 mx-auto">
            Are you sure you want to delete this account?
          </h4>
        </div>
        <div className="modal-footer d-block text-center border-0">
        <div>
            <button
              type="button"
              className="btn text-black fw-bold ok-delete py-3 w-100"
              onClick={deleteAccountHandler}
            >
              Ok
            </button>
          </div>

          <div>
            <button
              type="button"
              className="btn text-73 text-uppercase"
              aria-label="Close"
              onClick={cancelHandler}
            >
              Cancel
            </button>
          </div>
         
         
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteUser;
