import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../../pages/ButtomNav/Navbar";
import backArrow from "../../../../assets/arrow-white.png";
import buttomLine from "../../../../assets/line.svg";
import { FormValidation, FormValidationOnChange } from "./SupportValidation";
import { useSupportPostMutation } from "../../../../Services/SupportApi";
import { toast } from "react-toastify";
import Settings from "../../Settings";
import Sidebar from "../../../../components/Sidebar";
import AccountInformation from "../../accountInformation/AccountInformation";
import Legal from "../../legal/Legal";
import Support from "../../support/Support";
import dlogo from "../../../../assets/dlogo.png";
import ViewUserProfile from "../../accountInformation/userProfile/ViewUserProfile";
import notifications from "../../../../assets/notification-circle.svg";

const HelpAndSupport = () => {
  const navigate = useNavigate();
  const [helpAndSupport] = useSupportPostMutation();
  const [inputField, setInputField] = useState({
    email: "",
    subject: "",
    description: "",
  });

  const [errField, setErrField] = useState({
    emailErr: "",
    subjectErr: "",
    descriptionErr: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();

    if (FormValidation({ inputField, setErrField })) {
      helpAndSupport(inputField)
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          if (res?.success) {
            navigate("/settings");
          }
        })
        .catch((err) => {
          toast.error(err?.data?.message);
        });
    }
  };

  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    let inputName = e.target.name;
    let inputValue = e?.target?.value;
    FormValidationOnChange({ inputValue, setErrField, inputName });
  };

  return (
    <>
      {/* <Settings /> */}

      <div className="container-fluid bg-five">
        <div className="row px-0 pb-5 m-0">
          <div className="col-md-2 col-lg-1 new ">
            <Sidebar />
          </div>

          <div className="col-md-10 col-lg-11">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 p-0 m-0">
                    <div className="section">
                      <div className="">
                        <div className="top">
                          <div className="container-fluid  mt-40 p-0 mx-0">
                            <div className="row  align-items-center p-0 m-0">
                              <div className="col-3 p-0 m-0">

                              </div>

                              <div className="col-6 p-0 m-0">
                                <img
                                  src={dlogo}
                                  alt="notification"
                                  className="img-fluid mx-auto d-block"
                                  style={{ width: 150 }}
                                />
                              </div>


                              <div className="col-3 p-0 m-0">
                                <Link
                                  to={"/notifications"}
                                  className="notificaition-common border-0 p-0 list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <div className="ms-auto">
                                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                                      <img
                                        src={notifications}
                                        alt="notifications"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                  {/* <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span> */}
                                </Link>
                              </div>

                              {/* <div className="col-12">
                            <h3 className="design-color fw-bold mt-5">
                              {usrname}
                            </h3>
                          </div> */}
                          <div className="col-6 mt-5">
                            <h5 className="text-black fw-bold mb-4  borderLEFT">
                              Settings
                            </h5>
                          </div>
                            </div>
                          </div>
                        </div>

                        <div className="middle">
                          <div className="container-fluid">
                            <div className="row">

                              <div className="col-md-12">
                                {/* <p className="mb-4 text-uppercase text-decoration-underline ">
                              Account Info
                            </p> */}

                                <div className="list-group  text-nowrap list-group-horizontal gap-50 mt-2 user-profile-options">
                                  <AccountInformation />
                                  <Support />
                                  <Legal />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Navbar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="section bg-colorss bg-white mb-5 mb-md-3">
                  <div className="">
                    <div className="top">
                      <div className="container-fluid px-md-5 mt-4">
                        <div className="row">
                          <div className="col-md-12">
                            {/* <Link className="back-buttons" to={"/settings"}>
                              <img
                                src={backArrow}
                                alt="arrow"
                                className="img-fluid"
                              />
                            </Link> */}
                            <h5 className="design-color fw-bold mt-4 mb-5 text-center">
                              Help Support
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="middle">
                      <div className="container-fluid px-md-5 mt-4">
                        <div className="row">
                          <div className="col-md-12 mx-auto">
                            <div className="row">
                              <div className="col-md-12">
                                <form action="" className="form-login">
                                  <div className="mb-4">
                                    <label className="form-label">
                                      Email Address
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control py-3"
                                      placeholder="Enter Email Address"
                                      aria-label="email"
                                      aria-describedby="email"
                                      name="email"
                                      value={inputField.email}
                                      onChange={inputChangeHandler}
                                    />
                                    {errField?.emailErr?.length > 0 && (
                                      <span className="error">
                                        {errField?.emailErr}
                                      </span>
                                    )}
                                  </div>

                                  <div className="mb-4">
                                    <label className="form-label">
                                      Subject
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control py-3"
                                      placeholder="Enter Subject"
                                      aria-label="subject"
                                      aria-describedby="subject"
                                      name="subject"
                                      value={inputField.subject}
                                      onChange={inputChangeHandler}
                                    />
                                    {errField?.subjectErr?.length > 0 && (
                                      <span className="error">
                                        {errField?.subjectErr}
                                      </span>
                                    )}
                                  </div>

                                  <div className="">
                                    <label className="form-label">
                                      Description
                                    </label>
                                    <textarea
                                      id=""
                                      cols="30"
                                      rows="4"
                                      className="form-control"
                                      placeholder="Enter Description"
                                      name="description"
                                      value={inputField.description}
                                      onChange={inputChangeHandler}
                                    ></textarea>
                                    {errField?.descriptionErr?.length > 0 && (
                                      <span className="error">
                                        {errField?.descriptionErr}
                                      </span>
                                    )}
                                  </div>


                                  <div className="col-md-10 mx-auto">
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        onClick={submitHandler}
                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mb-4 mt-3"
                                      >
                                        Submit
                                      </button>
                                    </div></div>

                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bottom">
                      <div className="container-fluid px-md-100">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-center">
                              <img
                                src={buttomLine}
                                alt="line"
                                className="line img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
