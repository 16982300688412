import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../ButtomNav/Navbar";
import notificationIcon from "../../assets/notification-icon.svg";
import notifications from "../../assets/notification-circle.svg";
import dlogo from "../../assets/dlogo.png";

import AccountInformation from "./accountInformation/AccountInformation";
import Legal from "./legal/Legal";

import Support from "./support/Support";
import ViewUserProfile from "./accountInformation/userProfile/ViewUserProfile";
import ChangePassword from "./accountInformation/changePassword/ChangePassword";
import Sidebar from "../../components/Sidebar";

const Settings = () => {
  const [usrname, setUsername] = useState(localStorage.getItem("fullName"));
  const location = useLocation();

  console.log(location);

  return (
    <>
      <div className="container-fluid bg-five">
        <div className="row px-0 pb-5 m-0">
          <div className="col-md-2 col-lg-1 new ">
            <Sidebar />
          </div>
          <div className="col-md-10 col-lg-11">
            <div className="row">
              <div className="col-md-12 p-0 m-0">
                <div className="section">
                  <div className="height-100">
                    <div className="top">
                      <div className="container-fluid  mt-40 p-0 mx-0">
                        <div className="row  align-items-center p-0 m-0">
                          <div className="col-3 p-0 m-0">
                            {/* <h5 className="text-black fw-bold mb-0 design-color">Home</h5> */}
                          </div>

                          <div className="col-6 p-0 m-0">
                            <img
                              src={dlogo}
                              alt="notification"
                              className="img-fluid mx-auto d-block"
                              style={{ width: 150 }}
                            />
                          </div>


                          <div className="col-3">
                            <Link
                              to={"/notifications"}
                              className="notificaition-common border-0 p-0 list-group-item d-flex justify-content-between align-items-center"
                            >
                              <div className="ms-auto">
                                <div className="h5 mb-0 d-flex align-items-end setting-images">
                                  <img
                                    src={notifications}
                                    alt="notifications"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              {/* <span className="">
                                <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                            </span> */}
                            </Link>
                          </div>

                          {/* <div className="col-12">
                            <h3 className="design-color fw-bold mt-5">
                              {usrname}
                            </h3>
                          </div> */}
                          <div className="col-6 mt-5">
                            <h5 className="text-black fw-bold borderLEFT mb-4">
                              Settings
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="middle">
                      <div className="container-fluid">
                        <div className="row">
                          
                          <div className="col-md-12">
                            {/* <p className="mb-4 text-uppercase text-decoration-underline ">
                              Account Info
                            </p> */}

                            <div className="list-group  text-nowrap list-group-horizontal gap-50 mt-2 user-profile-options">
                              <AccountInformation />
                              <Support />
                              <Legal />
                            </div>
                          </div>

                          <div className="col-md-12">
                {location?.pathname === "/settings" && <ViewUserProfile />}
              </div>
                        </div>
                      </div>
                    </div>

                    <Navbar />
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
