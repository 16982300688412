

import {
    setFullName,
    setMobileNumber,
    setNumberOfProduct,
    setAddress,
    setZipCode,
  } from "../../Slices/createProfileSlice";
export const FormValidation = ({ formData, setErrField }) => {
    const validateForm = () => {
        let errors = {};
        if (!formData?.fullName?.trim()) {
            errors.fullNameErr = "Please enter FullName";
        } 
        if (!formData?.mobileNumber?.trim()) {
            errors.mobileNumberErr = "Please enter Mobile Number";
        } 
        else if (
            formData.mobileNumber.trim().length < 11
          ) {
            errors.mobileNumberErr = "Mobile number must be 10 digit";
          }
        if (!formData?.numberOfProduct) {
            errors.numberOfProductsErr = "Please enter Number of Product";
        }
        if (!formData?.address?.trim()) {
            errors.addressErr = "Please enter Address";
        }
        if (!formData?.zipCode) {
            errors.zipCodeErr = "Please enter Zip code";
        }
        else if(formData.zipCode.length < 5 ||
        formData.zipCode.length > 9){
            errors.zipCodeErr = "Zip code must be between 5 and 9 digit";
        }
        setErrField(errors);
        return Object.keys(errors)?.length === 0;
    };

    return validateForm();
};

export const FormValidationOnChange = ({ inputValue, setErrField, inputName,dispatch }) => {
    

    const validateForm = () => {
        
        if (inputName === "fullName" && inputValue?.length > 3) {
            dispatch(setFullName(inputValue))
            setErrField((prevState) => ({
                ...prevState,
                fullNameErr: "",
            }));
        }
        if (inputName === "mobileNumber" && inputValue?.length > 5) {
            dispatch(setMobileNumber(inputValue))
            setErrField((prevState) => ({
                ...prevState,
                mobileNumberErr: "",
            }));
        }
        if (inputName === "numberOfProduct" && inputValue?.length > 5) {
            dispatch(setNumberOfProduct(inputValue))
            setErrField((prevState) => ({
                ...prevState,
                numberOfProductsErr: "",
            }));
        }
        if (inputName === "address" && inputValue?.length > 5) {
            dispatch(setAddress(inputValue))
            setErrField((prevState) => ({
                ...prevState,
                addressErr: "",
            }));
        }
        if (inputName === "zipCode" && inputValue?.length > 5) {
            dispatch(setZipCode(inputValue))
            setErrField((prevState) => ({
                ...prevState,
                zipCodeErr: "",
            }));
        }
    };

    return validateForm();
};

