import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const forgotPasswordApi = createApi({
  reducerPath: "forgotPasswordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    ForgotPasswordPost: builder.mutation({
      query: (data) => ({
        url: "/retailer/forget-pass",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    ForgotVerificationPost: builder.mutation({
      query: (data) => ({
        url: "/retailer/verify-forget-pass",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    ForgotResendOtp: builder.mutation({
      query: (data) => ({
        url: "/retailer/forgot-resend-otp",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    CreateNewPassword: builder.mutation({
      query: (data) => ({
        url: "/retailer/change-pass",
        method: "PUT",
        body: data,
        headers: {
          "Accept": "application/json"
        }
      }),
    }),

  }),
});

export const {
  useForgotPasswordPostMutation,
  useForgotVerificationPostMutation,
  useForgotResendOtpMutation,
  useCreateNewPasswordMutation
} = forgotPasswordApi;
