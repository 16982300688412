import React,{useState} from 'react'
import { Link,useLocation  } from "react-router-dom";
import SplashIcon from "../assets/fav.png";
import LogoutModal from '../pages/settings/support/LogoutModal'

const Sidebar = () => {
  const [show,setShow] = useState(false)

  const location = useLocation();
  
  console.log("useLocation",location);


  const logoutHandler = () =>{
    setShow(true)
}
const handleClose = () =>{
    setShow(false)
}


  return (
    <div className="py-5 px-4">
    <img
      src={SplashIcon}
      alt="logo"
      className="img-fluid splash-logo-new"
    />
   {show && <LogoutModal show={show} handleClose={handleClose}/>}
    <ul className="nav flex-column mt-400 bg-warning  p-3">
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/dashboard" && 'active' }`} to={"/dashboard"}
        data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
          <i className="fa fa-home me-0"></i>
         <span> Home</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/products" && 'active' }`} to={"/products"}>
          <i className="fa fa-store me-0"></i>
         <span> My Products</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/my-menu" && 'active' }`} to={"/my-menu"}>
          <i className="fa fa-list me-0"></i>
       <span> My Orders</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/settings" && 'active' }`} to={"/settings"}>
          <i className="fa fa-cog me-0"></i>
        <span>  Settings</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/faq" && 'active' }`} to={"/faq"}>
          <i className="fa fa-question me-0"></i>
        <span>  FAQ</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/about-us" && 'active' }`} to={"/about-us"}>
          <i className="fa fa-circle-info me-0"></i>
         <span> About Us</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/term-and-condition" && 'active' }`} to={"/term-and-condition"}>
          <i className="fa fa-file-text me-0"></i>
         <span> User Terms</span>
        </Link>
      </li>
      <li className="nav-item mb-1">
        <Link className={`nav-link ${location.pathname==="/privacy-policy" && 'active' }`} to={"/privacy-policy"}>
          <i className="fa fa-lock me-0"></i>
         <span> Privacy Policy</span>
        </Link>
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={logoutHandler}>
          <i className="fa fa-right-from-bracket me-0"></i>
         <span> Logout</span>
        </a>
      </li>
    </ul>
  </div>
  )
}



export default Sidebar


