import React from 'react'
import { useNavigate } from 'react-router-dom';
import backArrow from "../../../assets/no-order.png";

const CompleteDelivered = (props) => {
    const navigate = useNavigate();
    const {onDataCompletedHanlder,activeSliderCompleted} = props
    const pendingDeliveries = [
        {
            orderId: '#456432432',
            orderDateTime: '11 March 2024, 03:00 AM',
            deliveryDateTime: '12 March 2024, 7:00 PM - 8:00 PM'
        },
        {
            orderId: '#456432433',
            orderDateTime: '12 March 2024, 03:00 AM',
            deliveryDateTime: '13 March 2024, 7:00 PM - 8:00 PM'
        },

    ];
    
    const handleClick = (id) => {
        navigate(`/order-details/${id}`);
    };
    return (
        <>

        <div className='no-order-product mt-4' >
     {onDataCompletedHanlder.length==0 &&  <img src={backArrow} alt="image" className="img-fluid" />}
    
        </div>
        <div className={`tab-pane fade ${activeSliderCompleted=='Completed' ? 'show active' : ''}`} id="complete" role="tabpanel" aria-labelledby="complete-tab" tabindex="0">
            {onDataCompletedHanlder?.map((delivery, index) => (
               <div key={index} onClick={() => handleClick(delivery._id)}>

           
                    <div className="card tab-card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <p className="mb-1">Order ID</p>
                                    {console.log("delivery---->",delivery?.doordash_order_id)}
                                    <h6 className="design-color">#{delivery?.doordash_order_id}</h6>
                                </div>
                                <div className="col-6">
                                    <div className="text-end">
                                        <button className="btn btn-delivery">Delivered</button>
                                    </div>
                                </div>

                                <div className="col-6 mt-2">
                                    <p className="mb-1">Order Date, Time</p>
                                    <h6 className="design-color">{delivery?.createdAt}</h6>
                                </div>
                                <div className="col-6 mt-2">
                                    <div className="float-end">
                                        <p className="mb-1">Delivery Date, Time</p>
                                        <h6 className="design-color">{delivery.order_dropoff_time}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            ))}
        </div>
        </>
    )
}

export default CompleteDelivered