import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import addImage from "../../../assets/add-images.svg";
import backArrow from "../../../assets/arrow-white.png";
import buttomLine from "../../../assets/line.svg";
import { useGetAllCategoryQuery } from "../../../Services/CategoryApi";
import { FormValidation } from "./AddProductValidation.js";
import { toast } from "react-toastify";
import LoadingAction from "../../../components/loader/LoadingAction";
import { useSelector } from "react-redux";
import {
  useGetSingleProductQuery,
  useEditProductMutation,
} from "../../../Services/ProductApi";
import { useDispatch } from "react-redux";
import { setUpdatedProduct } from "../../../Slices/productSlice.js";

const EditProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValueProductType, setSelectedValueProductType] = useState("");
  const { data } = useGetAllCategoryQuery({
    categoryIds: "",
    subcategoriesIds: "",
  });
  const [editProducts] = useEditProductMutation();
  const [categoryList, setCategoryList] = useState([""]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [image, setImage] = useState(null);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [itemWeight, setItemWeight] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const productId = useSelector((state) => state.productSlice);
  const [errField, setErrField] = useState({
    productNameErr: "",
    descriptionErr: "",
    categoryErr: "",
    selectedValueProductTypeErr: "",
    itemWeightErr: "",
    itemPriceErr: "",
    quantityErr: "",
    subCategoryErr: "",
  });
  const { data: getSingleProduct, isLoading } = useGetSingleProductQuery(
    productId?.product_id
  );

  useEffect(() => {
    setCategoryList(data?.data);
  }, [data]);
  useEffect(() => {
    setProductName(getSingleProduct?.data?.productName);
    setDescription(getSingleProduct?.data?.description);
    setCategory(getSingleProduct?.data?.category?._id);
    setSubCategoryList(getSingleProduct?.data?.category?.subCategory);
    setSelectedValueProductType(getSingleProduct?.data?.productType);
    setRegularPrice(getSingleProduct?.data?.regularPrice);
    setDiscountPrice(getSingleProduct?.data?.discountPrice);
    setItemWeight(getSingleProduct?.data?.itemWeight);
    setItemPrice(getSingleProduct?.data?.itemPrice);
    setQuantity(getSingleProduct?.data?.quantity);
    setSubCategory(getSingleProduct?.data?.subCategory);
  }, [getSingleProduct, productId]);

  // Event handler for select change
  const handleSelectChange = (event) => {
    setSelectedValueProductType(event.target.value);
    let inputValue = event.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        selectedValueProductTypeErr: "",
      }));
    }
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    const subCategoryData = JSON.parse(
      e.target.options[e.target.selectedIndex].getAttribute("data-custom")
    );
    setSubCategoryList(subCategoryData);
    let inputValue = e.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        categoryErr: "",
      }));
    }
  };
  const handleSubCategoryChange = (e) => {
    setSubCategory(e.target.value);
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    let inputValue = e.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        descriptionErr: "",
      }));
    }
  };
  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
    let inputValue = e.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        productNameErr: "",
      }));
    }
  };
  const handleRugalarPriceChange = (e) => {
    setRegularPrice(e.target.value);
  };
  const handleDiscountPriceChange = (e) => {
    setDiscountPrice(e.target.value);
  };
  const handleItemWeightChange = (e) => {
    setItemWeight(e.target.value);
    let inputValue = e.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        itemWeightErr: "",
      }));
    }
  };
  const handleItemPriceChange = (e) => {
    setItemPrice(e.target.value);
    let inputValue = e.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        itemPriceErr: "",
      }));
    }
  };
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
    let inputValue = e.target.value;
    if (inputValue?.length > 3) {
      setErrField((prevState) => ({
        ...prevState,
        quantityErr: "",
      }));
    }
  };

  const productSubmitHandler = (e) => {
    e.preventDefault();
    let inputField = {
      productName: productName,
      description: description,
      category: category,
      //selectedValueProductType: selectedValueProductType,
      itemWeight: itemWeight,
      itemPrice: itemPrice,
      quantity: quantity,
      // subCategory: subCategory,
    };
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("image", image);
    formData.append("itemWeight", itemWeight);
    formData.append("itemPrice", itemPrice);
    formData.append("quantity", quantity);

    if (FormValidation({ inputField, setErrField })) {
      setShowLoader(true);
      editProducts({ id: productId?.product_id, data: formData })
        .unwrap()
        .then((res) => {
          if (res?.success) {
            navigate("/products");
            dispatch(setUpdatedProduct(res.message));
            toast.success(res?.message);
            setShowLoader(false);
            setProductName("");
            setDescription("");
            setCategory("");
            setSubCategory("");
            setRegularPrice("");
            setDiscountPrice("");
            setItemWeight("");
            setItemPrice("");
            setQuantity("");
            setSelectedValueProductType("");
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.error(err?.data?.message);
        });
    }
  };

  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row">
              <div className="col-md-12">
                <Link className="back-buttons" to={"/products"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link>
                <h4 className="design-color fw-bold mt-4 mb-5 text-center ">
                  Edit Products
                </h4>
              </div>
            </div>
          </div>
        </div>
        {showLoader && <LoadingAction />}
        <div className="middle">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <form className="form-login">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-4">
                            <label className="form-label">Product Image</label>
                            <label
                              for="file-input"
                              id="custom-button"
                              className="edit flex-column-reverse justify-content-space-evenly file-upload"
                            >
                              <p className="text-ao small mb-0 fw-semibold">
                                {image?.size > 0
                                  ? "Add Images: " + image?.name
                                  : "Add Images"}
                              </p>
                              <img
                                src={addImage}
                                alt="upload"
                                className="img-fluid"
                              />
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="upload-product-images position-relative">
                            <img src="https://picsum.photos/200" alt="image" />

                            <div className="position-absolute cancel-image">
                              <i className="fa fa-close"></i>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-md-2">
                       <div className="upload-product-images active position-relative">
                       <img src="https://picsum.photos/200" alt="image" />
                       <div className="position-absolute cancel-image">
                        <i className="fa fa-close"></i>
                       </div>
                       </div>
                      </div>


                        <div className="col-md-2">
                          <div className="upload-product-images position-relative">
                            <img src="https://picsum.photos/200" alt="image" />

                            <div className="position-absolute cancel-image">
                              <i className="fa fa-close"></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="upload-product-images position-relative">
                            <img src="https://picsum.photos/200" alt="image" />

                            <div className="position-absolute cancel-image">
                              <i className="fa fa-close"></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="upload-product-images position-relative">
                            <img src="https://picsum.photos/200" alt="image" />

                            <div className="position-absolute cancel-image">
                              <i className="fa fa-close"></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="upload-product-images flex-column design-color">
                            <i className="fa fa-plus"></i>
                            <h6 className="mb-0 mt-2 fw-bolder">Add</h6>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 mt-4">
                        <label>Select Category</label>
                        <select
                          class="form-select form-control py-3"
                          aria-label="Default select example"
                          value={category}
                          onChange={handleCategoryChange}
                        >
                          <option value="">Select Category</option>
                          {categoryList?.map((category) => (
                            <option
                              key={category?._id}
                              value={category?._id}
                              data-custom={JSON.stringify(
                                category?.subCategory
                              )}
                            >
                              {category?.categoryName}
                            </option>
                          ))}
                        </select>
                        {errField?.categoryErr?.length > 0 && (
                          <span className="error">{errField?.categoryErr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label>Product Name</label>
                        <input
                          type="text"
                          className="form-control py-3"
                          placeholder="Enter Product Name"
                          aria-label="name"
                          aria-describedby="name"
                          value={productName}
                          onChange={handleProductNameChange}
                        />
                        {errField?.productNameErr?.length > 0 && (
                          <span className="error">
                            {errField?.productNameErr}
                          </span>
                        )}
                      </div>

                      <div className="mb-4">
                        <label>Description</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="4"
                          className="form-control"
                          value={description}
                          onChange={handleDescriptionChange}
                          placeholder="Enter Description"
                        ></textarea>
                        {errField?.descriptionErr?.length > 0 && (
                          <span className="error">
                            {errField?.descriptionErr}
                          </span>
                        )}
                      </div>

                      <div className="mb-4">
                        <label>Select Unit</label>
                        <select
                          class="form-select form-control py-3"
                          aria-label="Default select example"
                          value={category}
                          onChange={handleCategoryChange}
                        >
                          <option value="">Select Unit</option>
                          {categoryList?.map((category) => (
                            <option
                              key={category?._id}
                              value={category?._id}
                              data-custom={JSON.stringify(
                                category?.subCategory
                              )}
                            >
                              {category?.categoryName}
                            </option>
                          ))}
                        </select>
                        {errField?.categoryErr?.length > 0 && (
                          <span className="error">{errField?.categoryErr}</span>
                        )}
                      </div>

                      <div className="mb-4">
                        <label htmlFor="">Item Weight(kg)</label>
                        <input
                          type="text"
                          className="form-control py-3"
                          placeholder="Enter Item Weight (Each Item)"
                          aria-label="itemweight"
                          aria-describedby="itemweight"
                          value={itemWeight}
                          onChange={handleItemWeightChange}
                        />
                        {errField?.itemWeightErr?.length > 0 && (
                          <span className="error">
                            {errField?.itemWeightErr}
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <label>Item Price</label>
                        <input
                          type="number"
                          className="form-control py-3"
                          placeholder="Enter Item Price (Total Price)"
                          aria-label="itemprice"
                          aria-describedby="itemprice"
                          value={itemPrice}
                          onChange={handleItemPriceChange}
                        />
                        {errField?.itemPriceErr?.length > 0 && (
                          <span className="error">
                            {errField?.itemPriceErr}
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <label>Quantity</label>
                        <input
                          type="text"
                          className="form-control py-3"
                          placeholder="Enter Quantity"
                          aria-label="quantity"
                          aria-describedby="quantity"
                          value={quantity}
                          onChange={handleQuantityChange}
                        />
                        {errField?.quantityErr?.length > 0 && (
                          <span className="error">{errField?.quantityErr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-8 mx-auto">
                      <button
                        type="button"
                        onClick={productSubmitHandler}
                        data-bs-target="#profile-verification"
                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-3 mb-4 mt-3"
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  {/* row finish */}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
