import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const PerWeek = () => {
  const [data, setData] = useState({
    labels: ['week1', 'week2', 'week3', 'week4', 'week5', 'week6','week7','week8'],
    datasets: [
      {
        data: [24, 26, 30, 42, 51, 42,30,22,43],
        backgroundColor: 'rgba(33, 150, 243, 0.1)',
        borderColor: '#2196F3',
        fill: true,
        pointStyle: 'star',
        pointBorderColor: '#000',
        pointBackgroundColor: 'white',
      },
    ],
  });

  const options = {
    plugins: {
        legend: {
          display: false,
        },           
      },
    responsive: true, // Instruct chart js to respond nicely.
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        grid: {
          display: false, 
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};


export default PerWeek