import { DNA } from 'react-loader-spinner';

const MainLoader = () => {
    return (
        <div className="LoadingActionWrapper main-loader">
          <span class="loader"></span>
          
        </div>
      );
};

export default MainLoader;
